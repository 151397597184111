import './App.css';
import React, { useEffect } from "react";

function App() {
  useEffect(() => {
    const pathname = window.location.pathname.split('/').filter(v => v !== '').join('/'); // clean the path

    const redirect_list = {
      "about-us" : "about-us",
      "privacy-policy" : "privacy-policy",
      "privacy_policy" : "privacy-policy",
      "privacy-policy-eu" : "privacy-policy",
      "privacy-policy-microsoft" : "privacy-policy",
      "product" : "products",
      "how-itn-partnered-with-shortlist-to-achieve-huge-time-savings-and-stronger-relations" : "case-study/how-itn-partnered-with-shortlist-to-achieve-huge-time-savings-and-stronger-relations",
      "how-shortlist-helped-drops-achieve-incredible-time-savings-and-a-streamlined-custom-workflow" : "case-study/how-shortlist-helped-drops-achieve-incredible-time-savings-and-a-streamlined-custom-workflow",
      "how-switching-to-shortlist-helped-teacher-created-materials-save-time-and-develop-streamlined-custom-workflows" : "case-study/how-switching-to-shortlist-helped-teacher-created-materials-save-time-and-develop-streamlined-custom-workflows",
      "how-skyscanner-is-achieving-huge-time-and-cost-savings-with-shortlist" : "case-study/how-skyscanner-is-achieving-huge-time-and-cost-savings-with-worksuite-second",
      "enterprise" : "enterprise",
      "cookie-policy" : "cookie-settings",
      "security" : "security-overview",
      "customers" : "customers",
      "happy-customers" : "customers",
      "how-apartment-therapy-streamlined-their-freelancer-management-by-switching-to-shortlist" : "case-study/how-apartment-therapy-streamlined-their-freelancer-management-by-switching-to-worksuite",
      "how-shortlist-helps-flosports-organize-the-management-and-payment-of-20000-freelancers" : "case-study/how-worksuite-helps-flosports-organize-the-management-and-payment-of-20000-freelancers",
      "helping-publicis-manage-and-collaborate-with-1500-freelancers" : "case-study/helping-publicis-manage-and-collaborate-with-1500-freelancers",
      "case-study/helping-publicis-manage-and-collaborate-with-1500-freelancers-2" : "case-study/helping-publicis-manage-and-collaborate-with-1500-freelancers",
      "gdpr-ready" : "gdpr-overview",
      "helping-levitate-manage-production-freelancers" : "case-study/helping-levitate-manage-500-production-freelancers",
      "home-v2" : "",
      "solutions/onboarding-and-compliance" : "products/fms/onboarding-compliance",
      "pricing" : "products/fms/pricing",	
      "services/integrations/accelo" : "integrations",
      "services/integrations/awork" : "integrations",
      "services/integrations/beekeeper" : "integrations",
      "services/integrations/bexio" : "integrations",
      "services/integrations/box" : "integrations",
      "services/integrations/braintree" : "integrations",
      "services/integrations/chargebee" : "integrations",
      "services/integrations/chargeover" : "integrations",
      "services/integrations/chargify" : "integrations",
      "services/integrations/chatter" : "integrations",
      "services/integrations/cheddar" : "integrations",
      "services/integrations/clickup" : "integrations",
      "services/integrations/dropbox" : "integrations",
      "services/integrations/evernote" : "integrations",
      "integrations/financial-integrations" : "integrations",
      "services/integrations/%e2%80%8bframe-io" : "integrations",
      "services/integrations/freshbooks" : "integrations",
      "services/integrations/google-drive" : "integrations",
      "services/integrations/shortlist-google-sheets-integration-2" : "integrations",
      "services/integrations/google-tasks" : "integrations",
      "services/integrations/infusionsoft" : "integrations",
      "integrations" : "integrations",
      "services/integrations/jira-software-cloud" : "integrations",
      "services/integrations/lexoffice" : "integrations",
      "services/integrations/liquidplanner" : "integrations",
      "services/integrations/mattermost" : "integrations",
      "services/integrations/meistertask" : "integrations",
      "services/integrations/memberspace" : "integrations",
      "services/integrations/monday-com" : "integrations",
      "services/integrations/moneybird" : "integrations",
      "services/integrations/moonclerk" : "integrations",
      "services/integrations/onenote" : "integrations",
      "services/integrations/paypro" : "integrations",
      "services/integrations/pipedrive" : "integrations",
      "services/integrations/pipefy" : "integrations",
      "services/integrations/pivotal-tracker" : "integrations",
      "services/integrations/podio" : "integrations",
      "integrations/productivity-integrations" : "integrations",
      "integrations/project-management-integrations" : "integrations",
      "services/integrations/shortlist-quickbooks-integration-2" : "integrations",
      "services/integrations/recurly" : "integrations",
      "services/integrations/redmine" : "integrations",
      "services/integrations/revolut" : "integrations",
      "services/integrations/shortlist-salesforce-integration-2" : "integrations",
      "services/integrations/shortlist-asana-integration" : "integrations",
      "services/integrations/shortlist-basecamp-integration" : "integrations",
      "services/integrations/shortlist-google-calendar-integration" : "integrations",
      "services/integrations/shortlist-google-docs-integration" : "integrations",
      "services/integrations/shortlist-google-sheets-integration" : "integrations",
      "services/integrations/shortlist-mavenlink-integration" : "integrations",
      "services/integrations/shortlist-quickbooks-integration" : "integrations",
      "services/integrations/shortlist-salesforce-integration" : "integrations",
      "services/integrations/shortlist-slack-integration" : "integrations",
      "services/integrations/shortlist-teamwork-integration" : "integrations",
      "services/integrations/shortlist-trello-integration" : "integrations",
      "services/integrations/shortlist-xero-integration" : "integrations",
      "services/integrations/shortlist-slack-integration-2" : "integrations",
      "services/integrations/square" : "integrations",
      "services/integrations/streamtime" : "integrations",
      "services/integrations/3104" : "integrations",
      "services/integrations/things" : "integrations",
      "services/integrations/todoist" : "integrations",
      "services/integrations/shortlist-trello-integration-2" : "integrations",
      "services/integrations/wave-financial" : "integrations",
      "services/integrations/workast" : "integrations",
      "services/integrations/wrike" : "integrations",
      "services/integrations/wunderlist" : "integrations",
      "services/integrations/yammer" : "integrations",
      "services/integrations/zenkit" : "integrations",
      "services/integrations/zoho-projects" : "integrations",
      "services/integrations/zoho-subscriptions" : "integrations"
  }
  
    if (pathname in redirect_list) {
      window.location.href = "https://worksuite.com/" + redirect_list[pathname];
    } else {
      window.location.href = "https://worksuite.com/" + pathname;
    };

  }, []);

  return (
      <div className="App">
      </div>
  );
}

export default App;
